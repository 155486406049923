import { SnackbarKey, useSnackbar as snackbarHook } from "notistack";
import { Box, IconButton, Typography, useMediaQuery, useTheme } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import WarningIcon from "@mui/icons-material/Warning";
import "./style.scss";

type variant = "warning" | "default" | "error" | "success" | "info";

const useSnackbar = () => {
  const { enqueueSnackbar, closeSnackbar } = snackbarHook();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const action = (key: SnackbarKey | undefined) => (
    <IconButton
      size="small"
      onClick={() => {
        closeSnackbar(key);
      }}
      className="close-button"
    >
      <CloseIcon sx={{ color: "#fff" }}/>
    </IconButton>
  );

  const getBackgroundClass = (variant: variant) => {
    switch (variant) {
      case "warning":
        return "warning";
      case "error":
        return "error";
      case "success":
        return "success";
      case "info":
        return "info";
      default:
        return "default";
    }
  };

  const snackbar = (
    message = "Please enter",
    variant: variant = "warning",
    persist?: boolean
  ) =>
    enqueueSnackbar(message, {
      persist,
      variant,
      content: (key) => (
        <Box
          className={`snackbar-container ${isMobile ? "mobile" : "desktop"} ${getBackgroundClass(
            variant
          )}`}
        >
          <Box className="snackbar-content">
            <WarningIcon sx={{ color: "#fff" }} />
            <Typography
              className={`message ${isMobile ? "mobile" : "desktop"}`}
              variant="body1"
            >
              {message}
            </Typography>
          </Box>
          {!persist && action(key)}
        </Box>
      ),
      anchorOrigin: {
        vertical: isMobile ? "bottom" : "top",
        horizontal: isMobile ? "center" : "right",
      },
    });

  return { snackbar, closeSnackbar };
};

export default useSnackbar;